import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import Shipment from '@/domain/Shipment';
import ShipmentDetails from '@/domain/ShipmentDetails';

export default class ShippingApiService {
    public async lookupShipmentByTransactionNumberAndFromLocation(transactionNumber: number, fromLocationId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/transaction-lookup`,
            params: { transactionId: transactionNumber, fromLocationId },
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async save(dto: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateExistingShipment(dto: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping`,
            data: dto,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async createNewPickedShipment(dto: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/picked-shipment`,
            data: dto,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async moveTransactionsToPicked(shipmentId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/move-transactions-to-picked`,
            params: { shipmentId },
        });
        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateShipments(shipmentsToUpdate: Array<ShipmentDetails>): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/update-shipments`,
            data: shipmentsToUpdate,
        });
        return new DataAccessResponseClass<boolean>(response).response;
    }
}
